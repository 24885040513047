exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-js": () => import("./../../../src/pages/labs.js" /* webpackChunkName: "component---src-pages-labs-js" */),
  "component---src-pages-new-clients-js": () => import("./../../../src/pages/new-clients.js" /* webpackChunkName: "component---src-pages-new-clients-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-lab-js": () => import("./../../../src/templates/lab.js" /* webpackChunkName: "component---src-templates-lab-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

